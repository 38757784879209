@import url("https://fonts.googleapis.com/css2?family=Nanum+Pen+Script&display=swap");
.nanumpenscript * {
  font-family: "Nanum Pen Script", cursive;
}
@font-face {
  font-family: "Nanum Pen Script";
  font-style: normal;
  font-weight: 500;
  src: url("./util/fonts/nanum-pen-script-v15-latin_korean-regular.eot")
      format("embedded-opentype"),
    url("./util/fonts/nanum-pen-script-v15-latin_korean-regular.svg")
      format("svg"),
    url("./util/fonts/nanum-pen-script-v15-latin_korean-regular.ttf")
      format("truetype"),
    url("./util/fonts/nanum-pen-script-v15-latin_korean-regular.woff")
      format("woff"),
    url("./util/fonts/nanum-pen-script-v15-latin_korean-regular.woff2")
      format("woff2");
}

/* Noto Sans KR (korean) http://www.google.com/fonts/earlyaccess */
@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 100;
  src: url("./util/fonts/NotoSansKR-Thin.woff2") format("woff2"),
    url("./util/fonts/NotoSansKR-Thin.woff") format("woff"),
    url("./util/fonts/NotoSansKR-Thin.otf") format("opentype");
}
@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 300;
  src: url("./util/fonts/NotoSansKR-Light.woff2") format("woff2"),
    url("./util/fonts/NotoSansKR-Light.woff") format("woff"),
    url("./util/fonts/NotoSansKR-Light.otf") format("opentype");
}
@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 400;
  src: url("./util/fonts/NotoSansKR-Regular.woff2") format("woff2"),
    url("./util/fonts/NotoSansKR-Regular.woff") format("woff"),
    url("./util/fonts/NotoSansKR-Regular.otf") format("opentype");
}
@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 500;
  src: url("./util/fonts/NotoSansKR-Medium.woff2") format("woff2"),
    url("./util/fonts/NotoSansKR-Medium.woff") format("woff"),
    url("./util/fonts/NotoSansKR-Medium.otf") format("opentype");
}
@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 700;
  src: url("./util/fonts/NotoSansKR-Bold.woff2") format("woff2"),
    url("./util/fonts/NotoSansKR-Bold.woff") format("woff"),
    url("./util/fonts/NotoSansKR-Bold.otf") format("opentype");
}
@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 900;
  src: url("./util/fonts/NotoSansKR-Black.woff2") format("woff2"),
    url("./util/fonts/NotoSansKR-Black.woff") format("woff"),
    url("./util/fonts/NotoSansKR-Black.otf") format("opentype");
}
@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 900;
  src: url("./util/fonts/NotoSansKR-Black.woff2") format("woff2"),
    url("./util/fonts/NotoSansKR-Black.woff") format("woff"),
    url("./util/fonts/NotoSansKR-Black.otf") format("opentype");
}

html,
body {
  /* background-color: #000; */
  font-family: "Noto Sans KR";
}
.container {
  margin: 120px auto 0 auto;
  max-width: 1300px;
  /* background-color: #000;   */
  /* rgb(245, 245, 245); */
}
@media screen and (max-width: 600px) {
  .container {
    margin: 80px auto 0 auto;
  }
}
/* dark */
/* .container-dark {
  margin: 120px auto 0 auto;
  max-width: 1300px;
  background-color: rgb(24, 27, 44); 
  color: white;
}
@media screen and (max-width: 600px) {
  .container-dark {
    margin: 80px auto 0 auto;
    max-width: 1300px;
    min-height: 1000px;
  }
} */
/* .nav-container {
  margin: auto;
  background-color: #ffffff rgb(245, 245, 245);
} */

.footer-container {
  position: absolute;
  right: 0;
  left: 0;
  background-color: #4d4d4d;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: 0.28px;
  text-align: left;
  color: #8e8e8e;
  padding-top: 42px;
  padding-bottom: 2rem;
  padding-left: 2rem;
  margin-top: 30px;
}

.homefooter-container {
  position: absolute;
  right: 0;
  left: 0;
  top: 900px; /* 홈에서 계산된 위치 */
  background-color: #4d4d4d;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: 0.28px;
  text-align: left;
  color: #8e8e8e;
  padding-top: 42px;
  padding-bottom: 2rem;
  padding-left: 2rem;
  margin-top: 30px;
}
a {
  text-decoration: none;
}
@keyframes logoAnimation {
  5% {
    transform: rotate(-5deg) translate(0px, -8px);
  }
  10% {
    transform: rotate(0deg) translate(0px, -4px);
  }
  15% {
    transform: rotate(5deg) translate(0px, 0px);
  }
  20% {
    transform: rotate(0deg);
  }
}
@keyframes titleAnimation {
  5% {
    transform: rotate(0) translate(0px, -15px);
  }
  10% {
    transform: rotate(0deg) translate(0px, 5px);
  }
  15% {
    transform: rotate(0) translate(0px, 0px);
  }
  20% {
    transform: rotate(0);
  }
}
#logo {
  animation: logoAnimation 2s ease 0s infinite normal forwards;
}

#answer {
  animation: titleAnimation 2s ease 0s infinite normal forwards;
}
.marker {
  background-color: yellow;
}
blockquote {
  border-left: 5px solid #ccc;
  padding: 15px 0px 15px 15px
}