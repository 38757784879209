/* Full 캘린더 CSS 조절한 것 (다크모드 - 가맹원view)*/
.fc .fc-button-primary {
  background-color: #606168;
  border-color: transparent;
}
.fc .fc-bg-event {
  background-color: transparent;
  opacity: 1;
  border-color: #f15624;
  color: #181717;
}
.fc-daygrid {
  color: #000 !important;
}

.fc-day-fast {
  background-color: #000105 !important;
}
.fc-day-today {
  background-color: #e8dfdc !important;
}
.fc table {
  border-color: #2e2b2b;
  background-color: #f8f8f8;
  font-size: 12px;
  cursor: pointer;
}
.fc-theme-standard .fc-scrollgrid {
  border: 0;
}
.fc-theme-standard td,
.fc-theme-standard th {
  border: 0;
}
.fc .fc-daygrid-day-frame {
  display: flex;
  justify-content: center;
  align-items: center;
}
.fc th {
  background-color: #ebebeb;
  height: 47px;
  vertical-align: middle;
}

.fc .fc-scrollgrid-section-liquid {
  height: 1px !important;
}

.fc .fc-toolbar {
  background-color: #606168;
  flex: inherit;
}
.fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: 0;
  height: 46px;
}
.fc .fc-toolbar-title {
  font-size: 16px;
  color: #fff;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.32px;
  margin-left: 20px;
}
.fc-button-group:hover {
  background-color: #606168;
}
.fc-today-button .fc-button .fc-button-primary {
  display: none;
}
.fc-button {
  cursor: pointer;
}

